import {afterEachHandler, beforeEachHandler, commonRoutes} from '@jetCommon/router.js';
import {api} from '@/api';
import {createRouter, createWebHashHistory} from 'vue-router';
import {scrollBehavior} from '@jetCommon/router.js';

const routes = [
    ...commonRoutes,
    {
        path: '/',
        component: () => import('@/layouts/SidebarLayout.vue'),
        children: [
            {
                path: '',
                component: () => import('@/views/DashboardView.vue'),
                name: 'dashboardView',
            },
            {
                path: '/personale',
                component: () => import('@/views/PersonnelView.vue'),
                name: 'personnelView',
            },
            {
                path: '/presenze-assenze',
                component: () => import('@/views/AbsencesView.vue'),
                name: 'absencesView',
            },
            {
                path: '/voci-retribuzione',
                component: () => import('@/views/PayElementsView.vue'),
                name: 'payElementsView',
            },
            {
                path: '/elaborazione-paghe',
                component: () => import('@/views/PayrollView.vue'),
                name: 'payrollView',
            },
            {
                path: 'report',
                component: () => import('@/views/ReportsView.vue'),
                name: 'reportsView',
            },
            {
                path: 'documenti',
                component: () => import('@/views/DocumentsView.vue'),
                name: 'documentsView',
            },
            {
                path: 'impostazioni-azienda',
                component: () => import('@/views/CompanySettingsView.vue'),
                name: 'companySettingsView',
            },
        ],
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: () => '/404',
    },
];

const router = createRouter({
    scrollBehavior,
    history: createWebHashHistory(),
    routes,
});

router.beforeEach(async (to, from) => await beforeEachHandler(to, from, api));

router.afterEach(afterEachHandler);

export default router;
