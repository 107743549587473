import {end} from '@jetCommon/api.js';
import AuthApi from '@/api/auth.js';
import CCNLApiCommon from '@jetCommon/data-stores-api/ccnl.js';
import PersonApiCommon from '@jetCommon/api/person.js';

export const api = {
    end,
    auth: new AuthApi(),
    ccnl: new CCNLApiCommon(),
    persons: new PersonApiCommon(),
};
