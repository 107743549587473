import AuthApiCommon from '@jetCommon/api/auth.js';

export default class AuthApi extends AuthApiCommon {
    impersonateAdmin(adminId) {
        return this.apiPost('impersonate/', {admin: adminId});
    }

    impersonateEmployee(employeeId) {
        return this.apiPost('impersonate/', {employee: employeeId});
    }

    // Impersonate the first admin user in the company
    fastImpersonate(companyId) {
        return this.apiPost('impersonate/', {company: companyId});
    }
}
