<script setup>
    import 'element-plus/es/components/config-provider/style/index';
    import {ElConfigProvider} from 'element-plus';
    import {api} from '@/api';
    import {initJetTools} from '@jetCommon/jet-tools.js';

    import it from 'element-plus/dist/locale/it.mjs';
    provide(
        'jet',
        initJetTools({
            api,
            enableAuthStore: true,
        }),
    );
</script>

<template>
    <ElConfigProvider :locale="it">
        <router-view />
    </ElConfigProvider>
</template>
